// import React, { useState } from 'react';
// import { PayPalButtons } from "@paypal/react-paypal-js";
// import { toast } from "react-hot-toast";

// const PayPalButton = ({ amount, checkoutDetails }) => {

//   return (
//       <div>
//         <PayPalButtons
//           style={{ layout: "vertical" }}
//           createOrder={(data, actions) => {
//             return actions.order.create({
//               purchase_units: [
//                 {
//                   amount: {
//                     value: amount,
//                   },
//                   shipping: {
//                     address: {
//                       address_line_1: checkoutDetails.address1,
//                       address_line_2: checkoutDetails.address2,
//                       admin_area_2: checkoutDetails.city,
//                       admin_area_1: checkoutDetails.state,
//                       postal_code: checkoutDetails.postalCode,
//                       country_code: checkoutDetails.country,
//                     },
//                   },
//                   payer: {
//                     name: {
//                       given_name: checkoutDetails.firstName,
//                       surname: checkoutDetails.lastName,
//                     },
//                     email_address: checkoutDetails.email,
//                     phone: {
//                       phone_number: {
//                         national_number: checkoutDetails.phoneNumber,
//                       },
//                     },
//                   },
//                 },
//               ],
//             });
//           }}
//           onApprove={(data, actions) => {
//             return actions.order.capture().then((details) => {
//               toast.success("Payment successful!");
//             });
//           }}
//           onError={(err) => {
//             toast.error("Payment failed!");
//           }}
//         />
//       </div>
//   );
// };

// export default PayPalButton;

import React from "react";
import { PayPalButtons, FUNDING } from "@paypal/react-paypal-js";
import { Toaster, toast } from "react-hot-toast";
import { CreatePostRequest } from "../utils/api";

const PayPalButton = ({ amount, checkoutDetails, cartDetail }) => {
  const placeOrder = (details) => {
    const data = {
      products: JSON.stringify(cartDetail),
      email: checkoutDetails.email,
      firstName: checkoutDetails.firstName,
      lastName: checkoutDetails.lastName,
      address1: checkoutDetails.address1,
      address2: checkoutDetails.address2,
      country: checkoutDetails.country,
      postalCode: checkoutDetails.postalCode,
      city: checkoutDetails.city,
      state: checkoutDetails.state,
      phoneNumber: checkoutDetails.phoneNumber,
      totalAmount: amount,
      paymentMethod: "PayPal",
      paymentDetails: details,
    };
    CreatePostRequest("api/order/create", data).then((res) => {
      if (res.status) {
        toast.success(res.data.message);
      } else {
        toast.error("Something went wrong!");
      }
    });
  };

  return (
    <>
      <PayPalButtons
        style={{ layout: "vertical" }}
        fundingSource={FUNDING.PAYPAL}
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: amount.toString(),
                },
              },
            ],
          });
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then((details) => {
            toast.success(
              "Transaction completed by " + details.payer.name.given_name
            );
            placeOrder(details);
          });
        }}
        onError={(err) => {
          console.error(err);
          toast.error("Something went wrong ");
        }}
      />
      <PayPalButtons
        style={{ layout: "vertical" }}
        fundingSource={FUNDING.CARD}
        createOrder={(data, actions) => {
          if (!amount) {
            toast.error("Amount is missing or invalid.");
            return;
          }
          return actions.order.create({

            purchase_units: [
              {
                amount: {
                  value: amount.toString(),
                },
              },
            ],
          });
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then((details) => {
            toast.success(
              "Transaction completed by " + details.payer.name.given_name
            );
            placeOrder(details);
          });
        }}
        onError={(err) => {
          console.error(err);
          toast.error("Something went wrong ");
        }}
      />
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default PayPalButton;

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import RouterComponent from "./navigation/routerComponent";
import { Suspense } from "react";
import { ProvideAuth } from "./authorization/ProvidedAuth";
import FullScreenLoader from "./authorization/FullScreenLoader";
import { Provider } from "react-redux";
import store from "./app/store";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

let persistor = persistStore(store);

function App() {

const initialOptions = {
  "client-id": "ARtzp4j6JfEKnyvbfahLenE10jyG_T1fzl29MSLnW34w9pcR7qkMDU4l1RuFV_QyM_IzX9fuW3so1NYe",
  currency: "EUR",
  intent: "capture",
};

  return (
    <PayPalScriptProvider
      options={initialOptions}
    >
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Suspense fallback={<FullScreenLoader />}>
          <ProvideAuth>
            <RouterComponent />
          </ProvideAuth>
        </Suspense>
      </PersistGate>
    </Provider>
    </PayPalScriptProvider>
  );
}

export default App;
